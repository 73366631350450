export const levels = ['D1', 'L1', 'L2', 'L3']
export const TOP_FLOOR = 3
export const BOTTOM_FLOOR = 0
export const STARTING_FLOOR = 1

export const STARTING_FILTER = {
    elevators: true,
    bathrooms: false,
    study: false,
    stairs: false,
    food: false,
    student: false,
    academicDepts: false,
}

export const geoserverURL = "https://geoserver.appfactoryuwp.com/geoserver/uwp"
export const postGISURL = "https://geoserver.appfactoryuwp.com:3000/api"
export const bathroomsURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Abathrooms&maxFeatures=50&outputFormat=application%2Fjson`
export const studentServicesURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Astudent_services&maxFeatures=50&outputFormat=application%2Fjson`
export const elevatorsURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Aelevators&maxFeatures=50&outputFormat=application%2Fjson`
export const floorsURL = (floor) => `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3A${floor}-rooms&outputFormat=application%2Fjson`
export const studyAreasURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Astudy_areas&maxFeatures=50&outputFormat=application%2Fjson`
export const stairwellsURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Astairwells&maxFeatures=50&outputFormat=application%2Fjson`
export const foodServicesURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Afood_services&maxFeatures=50&outputFormat=application%2Fjson`
export const academicDepartmentsURL = `${geoserverURL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uwp%3Aacademic_departments&maxFeatures=50&outputFormat=application%2Fjson`

export const navigationLineColor = '#73A950'

// 	stairwells
// 	food_services
//  academic_departments