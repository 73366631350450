import {createSlice} from "@reduxjs/toolkit";
import {TOP_FLOOR, BOTTOM_FLOOR, STARTING_FLOOR} from "../helpers/constants";

const initialState = { value: STARTING_FLOOR }



const floorSlice = createSlice({
    name: 'floor',
    initialState,
    reducers: {
        increment(state) {
            if (state.value === TOP_FLOOR) {
                return
            }
            state.value++;
        },
        decrement(state) {
            if (state.value === BOTTOM_FLOOR) {
                return;
            }
            state.value--;
        },
        jumpToFloor(state, action) {
            state.value = action.payload
        }
    }
})

export const {increment, decrement, jumpToFloor} = floorSlice.actions
export default floorSlice.reducer