import {createSlice} from "@reduxjs/toolkit";
import {STARTING_FILTER} from "../helpers/constants";

const initialState = { value: STARTING_FILTER }


const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter(state, {payload}) {
            let {filter, value} = payload
            state.value = {
                ...state.value,
                [filter]: value
            }
        },
    }
})

export const {setFilter} = filterSlice.actions
export default filterSlice.reducer