import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Fill, Stroke, Style, Text} from "ol/style";
import generatePins from "./generatePins";
import {floorsURL, levels, STARTING_FLOOR} from "../../../helpers/constants";
import GeoJSON from "ol/format/GeoJSON";
import {useSelector} from "react-redux";

/*
    Function to show feature info
 */
let highlight;
const displayFeatureInfo = function (pixel, map, featureOverlay) {
    const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
    });

    if (feature !== highlight) {
        if (highlight) {
            featureOverlay.getSource().removeFeature(highlight);
        }
        if (feature) {
            featureOverlay.getSource().addFeature(feature);
        }
        highlight = feature;
    }
};

export function createMap(mapElement, floorLayers) {

    const currentFloor = STARTING_FLOOR;

    // Clear out any previous made maps
    const elements = document.getElementsByClassName('ol-viewport');
    if (elements[0]) {
        elements[0].remove()
    }

    if (mapElement) {

        // Create the map
        const map = new Map({
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url:
                            "https://api.mapbox.com/styles/v1/parksideindoor/ckl350mui1nbr17o26tgprkc3/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGFya3NpZGVpbmRvb3IiLCJhIjoiY2tnaGU1MHowMDN0czJ6bWU1ajVoeTlneCJ9.KfnSk61OsHyGuvWLn3M6bA"
                    })
                }),
            ],
            controls: [],
            target: mapElement,
            view: new View({
                center: [-49855035.18849698, 5258308.298133192],
                zoom: 18,
                minZoom: 18,
                maxZoom: 21
            })
        });

        // Create the overlay
        const featureOverlay = new VectorLayer({
            source: new VectorSource(),
            map: map,
            style: function styleFunction(feature) {
                return new Style({
                    fill: new Fill({
                        color: "#fff0df",
                    }),
                    stroke: new Stroke({
                        color: "#e5d2be",
                        width: 2
                    }),
                    zIndex: 1,
                    text: new Text({
                        font: '12px Calibri,sans-serif',
                        fill: new Fill({ color: '#797979' }),
                        // get the text from the feature - `this` is ol.Feature
                        // and show only under certain resolution
                        text: feature["values_"]["roomtype"] === 1 ? '' : feature["values_"]["full_ref"]
                    })
                })
            }
        })

        const navSelectionOverlay = new VectorLayer({
            source: new VectorSource(),
            map: map,
            style: new Style({
                zIndex: 2,
            })
        })

        const directionsOverlay = new VectorLayer({
            source: new VectorSource(),
            map: map,
            style: new Style({
                zIndex: 3,
            })
        })

        map.addLayer(floorLayers[currentFloor]);

        // Listener: Uncomment for hover effects and click effects
        // map.on("pointermove", function (evt) {
        //     if (evt.dragging) {
        //         return;
        //     }
        //     const pixel = map.getEventPixel(evt.originalEvent);
        //     displayFeatureInfo(pixel, map, featureOverlay);
        // });
        //
        // map.on("click", function (evt) {
        //     displayFeatureInfo(evt.pixel, map, featureOverlay);
        // });

        return {
            map,
            directionsOverlay,
            navSelectionOverlay,
            prevFloor: floorLayers[currentFloor],
            pins: generatePins(map)
        }
    } else {
        return {
            map: null
        }
    }
}