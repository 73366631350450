import React, {createContext, useEffect, useState} from "react";
import DirectionsSheet from "../../components/Navigation/Directions/DirectionsSheet";
import {useSelector} from "react-redux";
import {
    getPointFromRef,
    getPointId,
    getShortestPathRefined,
    getShortestPathStairsRefined
} from "../../helpers/requests";

export const DirectionsContext = createContext(undefined)

export default function DirectionsProvider({children}) {

    // Selected room is the destination
    const selectedRoom = useSelector((state) => state.selectedRoom.value);

    // cur floor
    const floor = useSelector((state) => state.floor.value);

    const [open, setOpen] = useState(false)
    const [directions, setDirections] = useState({
        startRef: null,
        endRef: null,
        features: {
            startPointRef: null,
            pathFeatures: null,
            paths: []
            // Any more details would go here
        },
    })

    const getFloorFromRef = (ref) => {
        let roomSplit = ref.split(' ')[1].split('')

        if (roomSplit[0] === 'D') {
            return 0;
        } else {
            return roomSplit[1]
        }
    }

    const [errorState, setErrorState] = useState({
        errorOpen: false,
        vertical: 'top',
        horizontal: 'center',
        message: ''
    });

    const handleErrorClose = () => {
        setErrorState({...errorState, errorOpen: false});
    };

    const startDirectionsTo = async (startRef) => {

        let paths = []

        let startingFloor = getFloorFromRef(startRef);
        let endingFloor = getFloorFromRef(directions.endRef);

        const startPointId = await getPointId(startRef)
        const endPointId = await getPointId(directions.endRef)

        let currentFloor = startingFloor
        let currentPointId = startPointId

        while ((currentFloor !== endingFloor)) {
            // Weird case, need variable to check if start is the start or the end
            // left = start; !left = end
            let left = false;

            const pathOnFloor = await getShortestPathStairsRefined(currentPointId, currentFloor);

            pathOnFloor.floor = currentFloor

            try {
                if (pathOnFloor.features[0].properties.start_id === currentPointId) {
                    left = true;
                }
            } catch(error) {
                setErrorState({...errorState, errorOpen: true, message: 'Could not determine path to destination.'})
                return;
            }

            if (left) {
                currentPointId = pathOnFloor.features[pathOnFloor.features.length - 1].properties.start_id
            } else {
                currentPointId = pathOnFloor.features[pathOnFloor.features.length - 1].properties.end_id
            }


            if (currentFloor < endingFloor) {
                currentFloor++;
            } else if (currentFloor > endingFloor) {
                currentFloor--;
            } else {
                currentFloor = endingFloor
            }

            paths.push(pathOnFloor)
        }

        const path = await getShortestPathRefined(currentPointId, endPointId)
        path.floor = currentFloor

        paths.push(path)

        setDirections({
            ...directions,
            features: {
                startPointRef: {
                    floor: startingFloor,
                    ref: startRef
                },
                pathFeatures: path,
                paths
            }
        })

        setOpen(false)
    }

    useEffect(() => {
        setDirections({
            ...directions,
            endRef: selectedRoom.currentRoom,
            features: {
                startPointRef: null,
                pathFeatures: null,
                paths: []
            }
        })
    }, [selectedRoom])

    return (
        <DirectionsContext.Provider value={{
            directions,
            directionSheetOpen: setOpen,
        }}>
            <DirectionsSheet directions={directions} open={open} setOpen={setOpen} startDirectionsTo={startDirectionsTo} 
                errorState={errorState} handleErrorClose={handleErrorClose} />
            {children}
        </DirectionsContext.Provider>
    )
}