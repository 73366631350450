import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment } from "../../../reducers/floorReducer";

import {IconButton} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {levels, TOP_FLOOR, BOTTOM_FLOOR} from "../../../helpers/constants";

const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  bottom: 50%;
  gap: 5px;
  z-index: 930;
`

const LevelButton = styled(IconButton)`
      "&:hover": {
        background-color: grey;
        cursor: pointer;
      };
      padding: 10px;
      background-color: white;
      box-shadow: 0 0 8px gray;
`

const FloorSelection = () => {

    const floor = useSelector((state) => state.floor.value);
    const dispatch = useDispatch();

    return (
        <MainContainer>
            {/*Upper arrow*/}
            <LevelButton
                onClick={() => dispatch(increment())}
                disabled={TOP_FLOOR === floor}
            >
                <KeyboardArrowUpIcon sx={{color:'black'}}/>
            </LevelButton>
            <br/>
            {/*Floor indicator*/}
            <IconButton
                disableRipple
                sx={{
                    "&:hover": {
                        backgroundColor: "rgb(1, 104, 54, 0.85)",
                        cursor: "unset"
                    },
                    bgcolor: '#016836',
                    color: 'white',
                    p: '22px'
                }}
            >
                <span style={{
                    fontSize: '14px'
                }}>
                    {levels[floor]}
                </span>
            </IconButton>
            <br/>
            {/*Down arrow*/}
            <LevelButton
                onClick={() => dispatch(decrement())}
                disabled={BOTTOM_FLOOR === floor}
            >
                <KeyboardArrowDownIcon sx={{color:'black'}}/>
            </LevelButton>
        </MainContainer>
    )
}

export default FloorSelection;