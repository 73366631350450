import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from "react-redux";
import store from "./store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import RoomsProvider from "./providers/RoomsProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import DirectionsProvider from "./providers/DirectionsProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: 'rgb(1, 104, 54, 0.85)',
        },
        secondary: {
            main: 'rgba(71,171,117,0.85)',
        },
    },
})


root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RoomsProvider>
              <ThemeProvider theme={theme}>
                  <DirectionsProvider>
                    <App />
                  </DirectionsProvider>
              </ThemeProvider>
          </RoomsProvider>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
