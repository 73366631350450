import { useState, useEffect } from 'react';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import {Container, IconButton} from "@mui/material";
import {styled} from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { setUserLocation } from "../../../reducers/userLocationReducer";

const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  bottom: 30px;
  width: 50px;
  z-index: 940;
`

export default function FindOwnLocation() {
    const [newUserLocation, setNewUserLocation] = useState({
        lat: null,
        lng: null,
    })
    const dispatch = useDispatch();

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setNewUserLocation({...newUserLocation, lat: position.coords.latitude, lng: position.coords.longitude});
            },
            error => {
                console.warn(`ERROR(${error.code}): ${error.message}`);
            });
        }
    }

    useEffect(() => {
        dispatch(setUserLocation(newUserLocation))
        console.log(newUserLocation);
    }, [newUserLocation]);

    return(
        <MainContainer>
            <IconButton
                sx={{
                    "&:hover": {
                        backgroundColor: "rgb(1, 104, 54, 0.85)",
                        cursor: "pointer"
                    },
                    color: 'white',
                    bgcolor: '#016836',
                    p: '15px'
                }}
                onClick={() => {
                    getUserLocation()
                }}
            >
                <MyLocationIcon/>
            </IconButton>
        </MainContainer>
    )
}