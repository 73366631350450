/*
    Function to generate floor layers
 */
import {floorsURL, levels} from "../../../helpers/constants";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {Fill, Stroke, Style, Text} from "ol/style";

export const generateFloorLayers = () => {
    let ret = []
    levels.forEach((level, i) => {
        ret.push(new VectorLayer({
            source: new VectorSource({
                url: floorsURL(level.toLowerCase()),
                format: new GeoJSON()
            }),
            style: function styleFunction(feature) {
                return new Style({
                    fill: new Fill({
                        // color: "#254441"
                        color: "#fff8eb"
                    }),
                    stroke: new Stroke({
                        // color: "#63938c",
                        color: "#e5d2be",
                        width: 1
                    }),
                    text: new Text({
                        font: '12px Calibri,sans-serif',
                        fill: new Fill({ color: '#797979' }),
                        // get the text from the feature - `this` is ol.Feature
                        // and show only under certain resolution
                        text: feature["values_"]["roomtype"] === 1 ? '' : feature["values_"]["full_ref"]
                    })
                })
            }
        }));
    })

    return ret
}