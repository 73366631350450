import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import "ol/ol.css";

import {Fill, Icon, Stroke, Style, Text} from "ol/style";

import {Feature} from "ol";
import {getPointFromRef} from "../../../helpers/requests";
import {Point} from "ol/geom";
import {jumpToFloor} from "../../../reducers/floorReducer";
import {createMap} from "./createMap";
import {generateFloorLayers} from "./generateFloorLayers";
import View from "ol/View";
import { fromLonLat, get } from 'ol/proj';
import useDirections from "../../../providers/DirectionsProvider/directions";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {navigationLineColor} from "../../../helpers/constants";



const ReactiveMap = () => {

    const currentFloor = useSelector((state) => state.floor.value);
    const selectedRoom = useSelector((state) => state.selectedRoom.value);
    const filter = useSelector((state) => state.filter.value);
    const userLocation = useSelector((state) => state.userLocation.value);
    const {directions} = useDirections()

    const floorLayers = generateFloorLayers();
    const [mapElement, setMapElement] = useState(null)

    const [currentRoomFeature, setCurrentRoomFeature] = useState(null);
    const [allLinesForNavigation, setAllLinesForNavigation] = useState([])

    const [startingPointFeature, setStartingPointFeature] = useState(null)

    const dispatch = useDispatch();
    const mapObj = useMemo(() => createMap(mapElement, floorLayers), [mapElement])
    const mapRef = useRef()
    mapRef.current = mapObj.map

    // Map cleanup
    const clearMap = () => {
        if (currentRoomFeature) {
            mapObj.navSelectionOverlay.getSource().removeFeature(currentRoomFeature)
        }
        if (allLinesForNavigation.length > 0) {
            console.log("trying to remove layer")
            for (let line of allLinesForNavigation) {
                mapRef.current.removeLayer(line)
            }
        }
    }

    // center view on user's current position when button is clicked (when geolocation updated)
    useEffect(() => {
        console.log(`userLocation updated! ${userLocation.lat}, ${userLocation.lng}`)
        if (mapObj.map && userLocation.lat && userLocation.lng) {
            console.log(`mapRef: ${mapRef.current}`);
            mapObj.map.setView(new View({
                center: fromLonLat([userLocation.lng, userLocation.lat]),
                zoom: 19,
                minZoom: 18,
                maxZoom: 21
            }))
        }
    }, [userLocation])

    useEffect(() => {
        if (mapObj.map) {
            updatePins()
        }
    }, [filter])

    useEffect(() => {
        if (mapObj.map) {
            if (selectedRoom.currentRoom || selectedRoom.previousRoom) {

                clearMap()

                if (!selectedRoom.currentRoom) {
                    mapObj.map.setView(new View({
                        center: [-49855035.18849698, 5258308.298133192],
                        zoom: 18,
                        minZoom: 18,
                        maxZoom: 21
                    }))
                    return
                }

                getPointFromRef(selectedRoom.currentRoom, ({features}) => {
                    let pinFeature = new Feature(new Point(features[0].geometry.coordinates))

                    console.log(features[0])

                    pinFeature.setStyle(new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            scale: 1,
                            src: "/assets/pins/selected.png"
                        }),
                        zIndex: 10
                    }))

                    mapObj.navSelectionOverlay.getSource().addFeature(pinFeature)
                    setCurrentRoomFeature(pinFeature)

                    const ex = pinFeature.getGeometry().getExtent()

                    console.log(selectedRoom)
                    dispatch(jumpToFloor(selectedRoom.floor))

                    mapObj.map.getView().fit(ex, mapObj.map.getSize());

                })

                //map.addLayer(selectedRoomLayer)

                console.log(mapRef.current)
                // mapRef.current.addFea
            }
        }


    }, [selectedRoom])

    useEffect(() => {
        if (mapObj.map) {
            if (directions.features.startPointRef) {
                getPointFromRef(directions.features.startPointRef.ref, ({features}) => {
                    let pinFeature = new Feature(new Point(features[0].geometry.coordinates))

                    pinFeature.setStyle(new Style({
                        image: new Icon({

                            scale: 0.5,
                            src: "/assets/pins/starting_pin.png"
                        }),
                        zIndex: 11
                    }))

                    setStartingPointFeature(pinFeature)
                    mapObj.navSelectionOverlay.getSource().addFeature(pinFeature)

                    const ex = pinFeature.getGeometry().getExtent()

                    dispatch(jumpToFloor(directions.features.startPointRef.floor))

                    mapObj.map.getView().fit(ex, mapObj.map.getSize());

                })
            }
        }
    }, [directions.features.startPointRef])

    useEffect(() => {
        console.log(directions.features.paths)
        console.log(mapObj.map && directions.features)
        if (!directions.features.paths) {return}

        if (mapObj.map && directions.features.paths.length > 0) {
            //
            // directions.features.pathFeatures
            //
            let lines = [];

            for (let path of directions.features.paths) {
                const pathPolylineLayer = new VectorLayer({
                    source: new VectorSource({
                        features: new GeoJSON().readFeatures(path),
                    }),
                    opacity: path.floor === currentFloor ? 1 : 0.25,

                    style: new Style({
                        fill: new Fill({
                            color: navigationLineColor,
                        }),
                        stroke: new Stroke({
                            color: navigationLineColor,
                            width: 5
                        }),
                        zIndex: 2,

                    })
                });

                pathPolylineLayer.setZIndex(5)
                pathPolylineLayer.floor = path.floor
                console.log('path floor set to: ', pathPolylineLayer.floor)
                lines.push(pathPolylineLayer)

                mapRef.current.addLayer(pathPolylineLayer)
            }

            setAllLinesForNavigation(lines)

        }
    }, [directions])

    // Updates when current floor changes
    useEffect(() => {
        if (mapObj.map) {
            mapRef.current.removeLayer(mapObj.prevFloor)
            mapRef.current.addLayer(floorLayers[currentFloor])
            mapObj.prevFloor = floorLayers[currentFloor]
            updatePins();

            if (allLinesForNavigation.length > 0) {
                for (let line of allLinesForNavigation) {
                    console.log("rerun check, ", line.floor)
                    console.log("rerun check, ", currentFloor)
                    line.setOpacity(line.floor == currentFloor ? 1 : 0.25)
                }
            }
        }
    }, [currentFloor])

    const updatePins = () => {
        let shownFeatures = [];

        // // Filter
        for (let pin of mapObj.pins) {
            if (filter[pin.type]) {
                shownFeatures.push(...pin.layer.getSource().getFeatures())
            } else {
                pin.layer.getSource().getFeatures().map(feature => {
                    feature.setStyle(new Style({
                        visibility: 'hidden'
                    }))
                })
            }
        }

        shownFeatures.map(feature => {
            if (parseInt(feature.getProperties().level) === currentFloor) {
                feature.setStyle(undefined)
            } else {
                feature.setStyle(new Style({
                    visibility: 'hidden'
                }))
            }
        })
    }






    return (
        <div>
            {/*<span id={"info"}>no selection</span>*/}
            <div ref={(ref) => {
                setMapElement(ref)
            }} id={"map"} style={{height: "100vh", width: "100%", zIndex: 1}}/>

        </div>

    )
}

export default ReactiveMap;