import React, {createContext, useEffect, useState} from "react";
import {postGISURL} from "../../helpers/constants";


export const RoomsContext = createContext(undefined);

export default function RoomsProvider({children}) {

    let [rooms, setRooms] = useState(undefined)
    let [err, setErr] = useState(false)

    useEffect(() => {
        if (!rooms) {
            fetch(`${postGISURL}/v1/rooms`).then(async (res) => {
                let roomResponse = await res.json()
                setErr(false)
                setRooms(roomResponse)
            }).catch((err) => {
                setErr(true)
                console.error("there was an error getting rooms, ", err)
            })
        }
    }, [])

    return (
        <RoomsContext.Provider value={{
            rooms,
            error: err
        }}>
            {children}
        </RoomsContext.Provider>
    )
}
