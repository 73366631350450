import React, {useState} from "react";

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    createFilterOptions,
    Icon,
    ListItemButton,
    Slide,
    Stack,
    Typography
} from "@mui/material";

import useAutocomplete from '@mui/material/useAutocomplete';
import List from '@mui/material/List';
import useRooms from "../../../providers/RoomsProvider/rooms";
import RoomIcon from '@mui/icons-material/Room';
import {useDispatch} from "react-redux";
import { setRoom, removeRoom } from "../../../reducers/selectedRoomReducer";
import useDirections from "../../../providers/DirectionsProvider/directions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PrimarySearchAppBar = (props) => {

    const {rooms} = useRooms()
    const {directionSheetOpen, directions} = useDirections()
    const [curValue, setCurValue] = useState(null);
    const [lastOptions, setLastOptions] = useState([]);
    const [textFieldFocused, setTextFieldFocused] = useState(false)
    const dispatch = useDispatch();

    const filterOptions = createFilterOptions();
    const MAX_SHOW = 20

    const {
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        options: rooms ? rooms : [],
        value: curValue,
        getOptionLabel: (option) => option ? option["full_ref"] : "",
        filterOptions: (options, state) => filterOptions(options, state).slice(0, MAX_SHOW)
    });

    return (
        <Paper sx={{width: "100%", backgroundColor: "transparent", position: "absolute", zIndex: 1000}} elevation={0}>
            <Slide direction="up" in={textFieldFocused} mountOnEnter unmountOnExit>
                <Paper sx={{backgroundColor: 'white', position: 'absolute', top: 0, height: "100vh", width: "100%", overflow: "hidden"}}>
                    <Box height={80}/>
                    <Divider/>
                    <Typography variant={"body2"} align={"left"} sx={{pl: 3, pt: 1}} gutterBottom>Results</Typography>
                    <List {...getListboxProps()} sx={{
                        overflow: 'auto',
                        p: 0,
                        m:0,
                        height: "100%",
                    }}>
                        {groupedOptions.length > 0 || lastOptions.length > 0 ? groupedOptions.map((option, index) => {
                                return (
                                    <>
                                        <ListItemButton {...getOptionProps({option, index})} key={`item-${index}`} sx={{zIndex: "9", height: 70}}
                                                        onClick={() => {
                                                            setCurValue(option)
                                                            dispatch(setRoom(option.full_ref))
                                                            document.getElementById('search-bar-field').blur();
                                                        }}
                                        >
                                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                                <Icon color={"primary"}>
                                                    <RoomIcon/>
                                                </Icon>
                                                <Stack style={{width: '100%'}}>
                                                    <Typography variant={"body1"} sx={{fontWeight: 450}}>{option['full_ref']}</Typography>
                                                    <Typography variant={"inherit"}>{option['building']}</Typography>
                                                </Stack>
                                            </Stack>

                                        </ListItemButton>
                                        <Divider variant="inset" component="li" />
                                    </>
                                )
                            }) :
                            <Stack alignItems={'center'}>
                                <SearchIcon/>
                                <Typography>No Results Found.</Typography>
                            </Stack>
                        }

                    </List>
                </Paper>
            </Slide>
            <Paper
                sx={{ p: 1, m: 2, mb: 0, display: 'flex', alignItems: 'center', borderRadius: 2, zIndex: 9999 }}
            >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                    {textFieldFocused ? <ArrowBackIcon/> : null}
                </IconButton>
                <InputBase
                    {...getInputProps()}

                    onFocus={(e) => {
                        setLastOptions([])
                        getInputProps().onFocus(e)
                        setTextFieldFocused(true)
                    }}
                    onBlur={(e) => {
                        setLastOptions(groupedOptions)
                        setTextFieldFocused(false)
                        getInputProps().onBlur(e)

                    }}
                    id={"search-bar-field"}
                    style={{ marginLeft: 1, flex: 1 }}
                    placeholder="Search"
                />
                {!textFieldFocused && curValue !== null ?
                    <IconButton sx={{ p: '10px' }} aria-label="search" onClick={() => {
                        setCurValue(null)
                        dispatch(removeRoom())
                    }}>
                        <CloseIcon />
                    </IconButton>
                    :
                    <IconButton sx={{ p: '10px' }} aria-label="search" onClick={() => {
                        if (lastOptions.length > 0) {
                            setCurValue(lastOptions[0])
                            dispatch(setRoom(lastOptions[0].full_ref))
                        }
                    }}>
                        <SearchIcon />
                    </IconButton>
                }

                {directions.endRef ?
                    <>
                        <Divider sx={{ height: 28, m: 0.5, zIndex: 1000 }} orientation="vertical" />
                        <IconButton sx={{ p: '10px'}} color={"primary"} aria-label="directions" onClick={() => {
                            directionSheetOpen(true)
                        }}>
                            <DirectionsIcon />
                        </IconButton>
                    </>
                    : null}

            </Paper>

        </Paper>

    )
}

export default PrimarySearchAppBar;