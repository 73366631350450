import {geoserverURL, postGISURL} from "./constants";


export const getPointFromRef = (ref, callback) => {
    fetch(geoserverURL + "/wfs?service=WFS&" +
        "&request=GetFeature" +
        "&version=1.0.0" +
        "&typeName=uwp:choose_full_ref" +
        "&outputformat=application/json" +
        "&viewparams=given:" + ref + ";").then(async (res) => {
            let data = await res.json();
            console.log("data: ", data)
            callback(data)
    })
}

export const getPointId = (ref) => {
    return new Promise((resolve, reject) => {
        fetch(`${postGISURL}/v1/vertex/${ref}`).then(async (res) => {
            let data = await res.json();
            console.log('data: ', data)
            console.log(`point id for ${ref}: `, data[0].id)
            resolve(data[0].id)
        }).catch(e => {
            reject(e)
        })
    })
}

export const getShortestPathRefined = (start, end) => {
    return new Promise((resolve, reject) => {
        fetch(geoserverURL + "/wfs?service=WFS" +
            "&version=1.0.0" +
            "&request=GetFeature" +
            "&typeName=uwp:shortest_path_refined" +
            "&outputformat=application/json" +
            "&viewparams=source:" + start + ";target:" + end).then(async (res) => {
                console.log(res)
                let data = await res.json();
                console.log(`path from ${start}->${end}: `, data)
                resolve(data)
        }).catch(e => {
            reject(e)
        })
    })
}

export const getShortestPathStairsRefined = (source, level) => {
    return new Promise((resolve, reject) => {
        fetch(geoserverURL + "/wfs?service=WFS" +
            "&version=1.0.0" +
            "&request=GetFeature" +
            "&typeName=uwp:shortest_path_stairs_refined" +
            "&outputformat=application/json" +
            "&viewparams=source:" + source + ";level:" + level).then(async (res) => {
                console.log('response ', res)
            let data = await res.json();
            console.log(`path from ${source} on level ${level}: `, data)
            resolve(data)
        }).catch(e => {
            reject(e)
        })
    })
}