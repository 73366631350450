import React, {useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    createFilterOptions,
    Divider,
    Icon,
    IconButton,
    InputBase,
    ListItemButton,
    Paper,
    Snackbar, 
    Slide,
    Stack,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {removeRoom, setRoom} from "../../../../reducers/selectedRoomReducer";
import RoomIcon from "@mui/icons-material/Room";
import List from "@mui/material/List";
import useAutocomplete from "@mui/material/useAutocomplete";
import useRooms from "../../../../providers/RoomsProvider/rooms";
import {useDispatch} from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsIcon from "@mui/icons-material/Directions";

export default function DirectionsSheet({directions, open, setOpen, startDirectionsTo, errorState, handleErrorClose}) {

    const {rooms} = useRooms()

    const [curValue, setCurValue] = useState({
        start: null,
        end: directions.endRef
    });

    const [lastOptions, setLastOptions] = useState([]);
    const [textFieldFocused, setTextFieldFocused] = useState(false)
    const dispatch = useDispatch();

    const filterOptions = createFilterOptions();
    const MAX_SHOW = 20

    const {
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        options: rooms ? rooms : [],
        value: curValue.start,
        getOptionLabel: (option) => option ? option["full_ref"] : "",
        filterOptions: (options, state) => filterOptions(options, state).slice(0, MAX_SHOW)
    });

    const inputStyle = {
        border: "1px solid lightgray",
        padding: "3px 10px",
        borderRadius: "8px",
    }

    const { errorOpen, vertical, horizontal, message } = errorState;

    return (
        <Paper sx={{width: "100%", backgroundColor: "transparent", position: "absolute", zIndex: 9999}} elevation={0}>
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>

                <Paper sx={{backgroundColor: 'white', position: 'absolute', top: 0, height: "100vh", width: "100%", overflow: "hidden"}}>
                    <Stack direction={'row'} alignItems={'center'} sx={{
                        m: 2
                    }}>
                        <IconButton onClick={() => {
                            setOpen(false)
                        }}>
                            <ArrowBackIcon sx={{
                                color: 'black'
                            }}/>
                        </IconButton>
                        <Stack direction={'column'} spacing={0.1} alignItems={'center'}>
                            <CircleOutlinedIcon/>
                            <MoreVertOutlinedIcon/>
                            <LocationOnOutlinedIcon color={'primary'} sx={{ fontSize: 32 }}/>
                        </Stack>
                        <Stack spacing={1} sx={{width: '75%'}}>
                            <InputBase
                                {...getInputProps()}
                                id={"search-field"}
                                placeholder="Choose a starting point"
                                sx={inputStyle}
                            />
                            <InputBase
                                id={"search-field"}
                                placeholder="End point"
                                disabled
                                value={directions.endRef}
                                sx={inputStyle}
                            />
                        </Stack>
                    </Stack>
                    <Divider/>
                    <Typography variant={"body2"} align={"left"} sx={{pl: 3, pt: 1}} gutterBottom>Results</Typography>
                    <List {...getListboxProps()} sx={{
                        overflow: 'auto',
                        p: 0,
                        m:0,
                        height: "100%",
                    }}>
                        {groupedOptions.length > 0 || lastOptions.length > 0 ? groupedOptions.map((option, index) => {
                                return (
                                    <>
                                        <ListItemButton {...getOptionProps({option, index})} key={`item-${index}`} sx={{zIndex: "9", height: 70}}
                                                        onClick={() => {
                                                            console.log(option)
                                                            startDirectionsTo(option.full_ref)
                                                            // setCurValue(option)
                                                            // dispatch(setRoom(option.full_ref))
                                                            // document.getElementById('search-bar-field').blur();
                                                        }}
                                        >
                                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                                <Icon color={"primary"}>
                                                    <RoomIcon/>
                                                </Icon>
                                                <Stack style={{width: '100%'}}>
                                                    <Typography variant={"body1"} sx={{fontWeight: 450}}>{option['full_ref']}</Typography>
                                                    <Typography variant={"inherit"}>{option['building']}</Typography>
                                                </Stack>
                                            </Stack>

                                        </ListItemButton>
                                        <Divider variant="inset" component="li" />
                                    </>
                                )
                            }) :
                            <Stack alignItems={'center'}>
                                <SearchIcon/>
                                <Typography>No Results Found.</Typography>
                            </Stack>
                        }

                    </List>
                    {errorOpen ? 
                        <Snackbar anchorOrigin={{vertical, horizontal}} open={errorOpen} autoHideDuration='6000' onClose={handleErrorClose} 
                                key={vertical + horizontal} sx={{top: "125px !important"}}>
                            <Alert severity="error" variant="filled" onClose={handleErrorClose}>
                                <AlertTitle>Error</AlertTitle>
                                {message}
                            </Alert>
                        </Snackbar> 
                        : <></>
                    }
                </Paper>
            </Slide>


        </Paper>
    )
}