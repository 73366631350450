import './App.css';

// react
import React, { useState, useEffect } from 'react';

// components
import ReactiveMap from "./components/Map/ReactiveMap";
import FloorSelection from "./components/Navigation/FloorSelection";
import PrimarySearchAppBar from "./components/Navigation/SearchBar";
import FindOwnLocation from "./components/Navigation/Location";
import LoadingScreen from "./components/LoadingScreen";
import useRooms from "./providers/RoomsProvider/rooms";
import FilterButton from "./components/Navigation/Filter";

function App() {

    let {rooms} = useRooms()

    return (
      <div className="App">
          <LoadingScreen loading={!rooms}/>
          <PrimarySearchAppBar/>
          <FloorSelection/>
          <FilterButton/>
          <FindOwnLocation/>
          <ReactiveMap/>
      </div>
    )
}

export default App
