import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: {
        previousRoom: null,
        currentRoom: null,
        floor: 0
    }
}

const getFloorFromRoom = (room) => {
    let arr = room.split(' ')[1].split('')

    console.log(arr)

    if (arr[0] === "D") {
        return 0
    } else if (arr[1] === "1") {
        return 1
    } else if (arr[1] === "2") {
        return 2
    } else if (arr[1] === "3") {
        return 3
    }
}


const selectedRoomSlice = createSlice({
    name: 'selectedRoom',
    initialState,
    reducers: {
        setRoom(state, {payload}) {
            state.value = {
                previousRoom: state.value.currentRoom,
                currentRoom: payload,
                floor: getFloorFromRoom(payload)
            }
        },
        removeRoom(state) {
            state.value = {
                ...state.value,
                previousRoom: state.value.currentRoom,
                currentRoom: null
            }
        },
    }
})

export const {setRoom, removeRoom} = selectedRoomSlice.actions
export default selectedRoomSlice.reducer