import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {
    academicDepartmentsURL,
    bathroomsURL,
    elevatorsURL,
    foodServicesURL,
    stairwellsURL, STARTING_FILTER, STARTING_FLOOR,
    studentServicesURL,
    studyAreasURL
} from "../../../helpers/constants";
import GeoJSON from "ol/format/GeoJSON";
import {Icon, Style} from "ol/style";


export default function generatePins(map) {

    const elevators = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: elevatorsURL,
            format: new GeoJSON()
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/elevators.png"
            }),
            zIndex: 2
        })
    })

    const studentServices = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: studentServicesURL,
            format: new GeoJSON()
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/student_services.png"
            }),
            zIndex: 2
        })
    })

    const bathrooms = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: bathroomsURL,
            format: new GeoJSON()
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/bathrooms.png"
            }),
            zIndex: 2
        })
    });

    const studyAreas = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: studyAreasURL,
            format: new GeoJSON()
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/study_areas.png"
            }),
            zIndex: 2
        })
    });

    const stairwells = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: stairwellsURL,
            format: new GeoJSON()
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/stairs.png"
            }),
            zIndex: 2
        })
    });

    const foodServices = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: foodServicesURL,
            format: new GeoJSON(),
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/food_services.png"
            }),
            zIndex: 2
        })
    });

    const academicDepartments = new VectorLayer({
        map: map,
        source: new VectorSource({
            url: academicDepartmentsURL,
            format: new GeoJSON()
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                scale: 0.5,
                src: "/assets/pins/academic_departments.png"
            }),
            zIndex: 2
        })
    });

    let retPins = [
        // {
        //     layer: elevators,
        //     type: "elevators"
        // },
        {
            layer: studentServices,
            type: "student"
        },
        {
            layer: bathrooms,
            type: "bathrooms"
        },
        {
            layer: studyAreas,
            type: "study"
        },
        // {
        //     layer: stairwells,
        //     type: "stairs"
        // },
        {
            layer: foodServices,
            type: "food"
        },
        {
            layer: academicDepartments,
            type: "academicDepts"
        }
    ]

    // Need this to check if the pins have loaded then to hide them once they are showing
    const checker = setInterval(() => {
        if (studentServices.getSource().getFeatures().length > 0 &&
            bathrooms.getSource().getFeatures().length > 0 &&
            studyAreas.getSource().getFeatures().length > 0 &&
            stairwells.getSource().getFeatures().length > 0 &&
            foodServices.getSource().getFeatures().length > 0 &&
            academicDepartments.getSource().getFeatures().length > 0
        ) {
            setPins(retPins)
            clearInterval(checker)
        }
    }, 300)


    return retPins
}

const setPins = (pins) => {
    let shownFeatures = [];

    // // Filter
    for (let pin of pins) {
        if (STARTING_FILTER[pin.type]) {
            console.log(pin.layer.getSource().getFeatures())
            shownFeatures.push(...pin.layer.getSource().getFeatures())
        } else {
            pin.layer.getSource().getFeatures().map(feature => {
                feature.setStyle(new Style({
                    visibility: 'hidden'
                }))
            })
        }
    }

    shownFeatures.map(feature => {
        console.log(feature.getProperties().level === STARTING_FLOOR)

        if (!feature.getProperties().level) {
            feature.setStyle(undefined)
            return
        }

        if (parseInt(feature.getProperties().level) === STARTING_FLOOR) {
            console.log("Showing feature for floor, ", STARTING_FLOOR);
            feature.setStyle(undefined)
        } else {
            feature.setStyle(new Style({
                visibility: 'hidden'
            }))
        }
    })
}