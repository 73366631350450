// Import reducers
import floorReducer from "./floorReducer";
import selectedRoomReducer from "./selectedRoomReducer";
import filterReducer from "./filterReducer";
import userLocationReducer from "./userLocationReducer";

// Add them to the reducers export
const reducers = {
    floor: floorReducer,
    selectedRoom: selectedRoomReducer,
    filter: filterReducer,
    userLocation: userLocationReducer,
}

export default reducers;