import React from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box"
import {Stack, SwipeableDrawer, Switch, Typography} from "@mui/material";
import { Global } from '@emotion/react';
import {useDispatch, useSelector} from "react-redux";
import {setFilter} from "../../../../reducers/filterReducer";

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    zIndex: 999
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: "gray",
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const drawerBleeding = 56;

export default function FilterSheet({open, setOpen}) {

    const state = useSelector((state) => state.filter.value);
    const dispatch = useDispatch();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleChange = (event) => {
        dispatch(setFilter({
            filter: event.target.name,
            value: event.target.checked
        }))
    };

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    },
                }}
            />
            <StyledBox
                sx={{
                    position: 'absolute',
                    visibility: 'visible',
                    zIndex: 10000,
                    right: 0,
                    left: 0,
                }}
            >
                <Puller />
            </StyledBox>
            <StyledBox
                sx={{
                    px: 2,
                    pb: 2,
                    mt: 5,
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: "bold", fontSize: 21, mb:2}}>Filter</Typography>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Bathrooms</Typography>
                        <Switch value={state.bathrooms} onChange={handleChange} name={"bathrooms"}/>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Study Areas</Typography>
                        <Switch value={state.study} onChange={handleChange} name={"study"}/>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Stairs</Typography>
                        <Switch value={state.stairs} onChange={handleChange} name={"stairs"}/>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Food Services</Typography>
                        <Switch value={state.food} onChange={handleChange} name={"food"}/>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Student Services</Typography>
                        <Switch value={state.student} onChange={handleChange} name={"student"}/>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Academic Departments</Typography>
                        <Switch value={state.academicDepts} onChange={handleChange} name={"academicDepts"}/>
                    </Stack>
                </Stack>
            </StyledBox>
        </SwipeableDrawer>
    )
}