import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: {
        lat: null,
        lng: null,
    }
}

const userLocationSlice = createSlice({
    name: 'userLocation',
    initialState,
    reducers: {
        setUserLocation(state, {payload}) {
            const userLocation = payload;
            state.value = {
                lat: payload.lat,
                lng: payload.lng
            }
        },
    }
})

export const {setUserLocation} = userLocationSlice.actions
export default userLocationSlice.reducer