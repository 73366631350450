import React, {useState} from "react";
import FilterButton from "./FilterButton";
import FilterSheet from "./FilterSheet";


export default function Filter() {

    let [filterOpen, setFilterOpen] = useState(false)


    return(
        <>
            <FilterSheet open={filterOpen} setOpen={setFilterOpen}/>
            <FilterButton setFilterOpen={setFilterOpen}/>
        </>
    )
}