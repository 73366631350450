import React, {useState} from "react";
import {IconButton} from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {styled} from "@mui/material/styles";

const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  bottom: 30px;
  width: 50px;
  z-index: 940;
`

export default function FilterButton({setFilterOpen}) {

    return(
        <MainContainer>
            <IconButton
                sx={{
                    "&:hover": {
                        backgroundColor: "rgb(1, 104, 54, 0.85)",
                        cursor: "pointer"
                    },
                    color: 'white',
                    bgcolor: '#016836',
                    p: '15px'
                }}
                onClick={() => {
                    setFilterOpen(true)
                }}
            >
                <FilterAltOutlinedIcon/>
            </IconButton>
        </MainContainer>
    )
}